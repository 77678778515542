import React, { Component } from 'react';
import EncryptSmz from './encrypt';
import DecryptSmz from './decrypt';
import CryptoJS from 'crypto-js';

class Tabs extends Component {
    state = {  
        count: 0,
        tab: 1,
        estring: '',
        dstring: '',
        message: '',
        key: this.getDate(),
    };
    
    
    componentDidMount(){
        // let cryptsrc = "https://cdnjs.cloudflare.com/ajax/libs/crypto-js/3.1.2/rollups/aes.js";
        // const cscript = document.createElement("script");
        // cscript.src = cryptsrc;
        // cscript.async = true;
        
        // document.body.appendChild(cscript);
        //let CryptoJS = require("crypto-js");
        
    }

    //set default key to current time rand string on first load
    setKey(){
        //this.state.key = Date('YMDHMS');
        let newkey = this.getDate();
        this.setState({key:newkey});
    }
    updateKey(val){
        let newKey = val;

        this.setState({key:newKey});
    }
    
    getDate(){
        let today = new Date();
        let  d = String(today.getDate()).padStart(2, '0');
        let  m = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let  y = today.getFullYear();

        return y+'-'+m+'-'+d;
    }

    updateDString = (plaintext) =>{
        this.setState({dstring:plaintext});
    }
    updateEString = (encryptstring) => {
        this.setState({estring:encryptstring});
    }
    encryptString = e => {
        console.log('handle encryption');
        e.preventDefault();
        // Encrypt
        let rawstring = this.state.dstring;
        let key = this.state.key;
        let ciphertext = CryptoJS.AES.encrypt(rawstring, key).toString();
        let emessage = "Text Encrypted";
        this.setState({
            estring: ciphertext,
            message: emessage,
        });

        console.log('new cipher', ciphertext);
        return false;
    }
    decryptString = e => {
        console.log('handle decryption');
        e.preventDefault();
        // Decrypt
        let enstring = this.state.estring;
        let bytes = CryptoJS.AES.decrypt(enstring, this.state.key);
        let plaintext = bytes.toString(CryptoJS.enc.Utf8);
        
        let dmessage = "Text decrypted";

        console.log('plaintext found: ', plaintext);
        this.setState({
            dstring : plaintext,
            message: dmessage
        })
    }

    //set active tab based on tab number
    checkTab = () => {
        let c1 = 'tbtn';
        let c2 = 'tbtn';
        if(this.state.tab === 1){
            //active on tab 1
            c1 = 'tbtn active';
        }
        else if(this.state.tab === 2){
            //active on tab 2
            c2 = 'tbtn active';
        }
        else{
            return <p>No Tab Set</p>;
        }

        return (
            <ul>
                <li><div onClick={()=> this.onTabClick(1)} className={c1} id="one" data-target="econt">Encrypt</div></li>
                <li><div onClick={()=> this.onTabClick(2)} className={c2} id="two" data-target="dcont">Decrypt</div></li>
            </ul> 
        );
    };

    onTabClick = (id) =>{
        this.setState({tab: id});
    };


    copyToClipboard = (e, ref) => {
        e.preventDefault();
        let textarea = document.getElementById(ref);
        textarea.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the the whole text area selected.
        e.target.focus();
        this.setState({ message: 'Text Copied!' });
    };

    
    //set which container is active
    setContainer = () =>{
        let c1 = 'econt hidden';
        let c2 = 'dcont hidden';
        if(this.state.tab === 1){
            //active on tab 1
            c1 = 'econt active';
        }
        else if(this.state.tab === 2){
            //active on tab 2
            c2 = 'dcont active';
        }

        return(
            <div className="tab-content">
                <div className={c1}><h2>Encrypt</h2>
                    <div className="content"><EncryptSmz 
                    edata={this.state.estring} 
                    ddata={this.state.dstring} 
                    onEncrypt={this.encryptString} 
                    dChange={this.updateDString} 
                    message={this.state.message}
                    onCopy={this.copyToClipboard}
                    /></div>
                </div>
                <div className={c2}><h2>Decrypt</h2>
                    <div className="content"><DecryptSmz 
                    edata={this.state.estring} 
                    ddata={this.state.dstring} 
                    onDecrypt={this.decryptString} 
                    eChange={this.updateEString} 
                    message={this.state.message}
                    onCopy={this.copyToClipboard}
                    /></div>
                </div>
            </div>

        );
    };


    render() { 

        return (  
            <div className="encrypter-wrapper">
                
                <div className="tab-container">
                {this.checkTab()}
                </div>
                <div className="form-wrapper">
                    <div className="iwrapper">
                        <h3>Key</h3>
                        <div><p>Type a word to use as a key to encode your message</p></div>
                        <input 
                            type="text" 
                            id="edkey" 
                            value={this.state.key} 
                            onChange={ e => this.updateKey(e.target.value)} 
                            /><button
                                onClick={e => this.copyToClipboard(e,'edkey')}
                            >Copy Key</button>
                    </div>
                    {this.setContainer()}
                </div>
                
            </div>

        );
    }
}
 
export default Tabs;