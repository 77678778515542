import React, { Component } from 'react';

class EncryptSmz extends Component {
    render() { 
        return ( 
            <div className="econt">
                <form >
                    <div className="message">{this.props.message}</div>
                    
                    <div className="iwrapper">
                        <h5>Plain Text</h5>
                        <textarea 
                            name="rawtext" 
                            id="plaininput" rows="5" 
                            value={this.props.ddata} 
                            onChange={e => this.props.dChange(e.target.value)} 
                            placeholder="Type your message to be encrypted and click the button below"
                            ></textarea>
                    </div>
                    <div><button onClick={e => this.props.onEncrypt(e)}>Generate</button></div>
                    <div className="iwrapper">
                        <h5>Encrypted Text</h5>
                        <textarea readOnly name="resulttext" id="ciphertext" rows="5" value={this.props.edata} placeholder="Your encrypted text will appear here when generated"></textarea>
                    </div>
                    <button onClick={e => this.props.onCopy(e,'ciphertext')}>Copy</button>
                </form>
            </div>

         );
    }
}
 
export default EncryptSmz;

