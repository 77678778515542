import React, { Component } from 'react';

class DecryptSmz extends Component {
    
    render() { 
        return ( 
            <div className="econt">
                <form action="">
                    <div className="message">{this.props.message}</div>
                    <div className="iwrapper">
                        <h5>Encrypted Text</h5>
                        <textarea 
                            name="ciphertext" 
                            id="cipherinput" rows="5" 
                            value={this.props.edata} 
                            onChange={ e => this.props.eChange(e.target.value)}
                            placeholder="Add your enrypted message here"
                            ></textarea></div>
                    <div>
                        <button onClick={e => this.props.onDecrypt(e)}>Decrypt</button></div>
                    <div className="iwrapper">
                        <h5>Plain Text</h5>
                        <textarea 
                            name="resulttext" 
                            id="plaintext" rows="5" readOnly 
                            value={this.props.ddata}
                            placeholder="Your message will be decoded here"
                            ></textarea>
                    </div>
                    <button onClick={e => this.props.onCopy(e,'plaintext')}>Copy</button>
                </form>
            </div>

         );
    }
}
 
export default DecryptSmz;

