import React, { Component } from 'react';

class Extras extends Component {
    state = {  }
    render() { 
        return ( 
            <div>
                Copyright {'\u00A9'} {(new Date().getFullYear())} - All rights reserved. 
            </div>

         );
    }
}
 
export default Extras;